:has(>.content-wrapper) {
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 60px 0px;
}

input {
    border-radius: 0px !important;
}

.content-wrapper {
    width: 60%;
    display: flex;
    flex-direction: column;
    row-gap: 45px;
}

.suggestions-wrapper {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 30px;
}

.suggestion-wrapper:hover {
    background: rgba(255, 255, 255, 0.09);
    transition: .3s ease-out;
}

.suggestion-wrapper {
    display: flex;
    justify-content: space-between;
    width: 45%;
    padding: 33px 24px;
    margin: 15px 0px;
    border: 1px solid #272727;
    color: #5b5b5b;
    background: rgba(255, 255, 255, 0.03);
    cursor: pointer;
}